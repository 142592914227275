import {
  MutateOptions,
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { message } from "antd";
import { AxiosError } from "axios";
import { useDispatch, useSelector } from "react-redux";

import { RootState } from "../../../../store";
import { SCSelectType } from "../../WorkforceProfile/model";
import {
  AddTeamMemberPayload,
  AssignCoursePayload,
  CreateEditTeamPayload,
  DeleteMemberPayload,
  DeleteMemberResponse,
  DeleteTeamPayload,
  EditTeamMemberPayload,
  GenericResponse,
  GetAssignedCoursesListOfTeamMember,
  GetAssignedCoursesListOfTeamMemberType,
  GetCoursesListOfMember,
  GetTeamByIdPayload,
  GetTeamMemberRolesPermissionPayload,
  GetTeamMembersPayload,
  GetTeamMembersToAssignPayload,
  GetTeamWorkForceList,
  GetTeamWorkForceListParamsType,
  GetTeamMembersToAssignResponse,
  GetTeamsAssignedCoursesList,
  GetTeamsAssignedCoursesListParamsType,
  GetTeamsAvailableCoursesList,
  GetTeamsMemberList,
  GetTeamsMemberListParamsType,
  GetTeamsPayload,
  GetTeamsResponse,
  GetUsersForNonTeamMembersPayload,
  GetUsersForTransferTeamsPayload,
  IExtendDeadLineRequest,
  ISendMessageToLeads,
  ISendMessageToMembers,
  TeamsAvailableCoursesRequestType,
  TransferTeamOwnerShip,
  UnAssignCoursePayload,
  ValidateUserBeforeTeamDetailPagePayload,
  addTeamMember,
  assignCourse,
  createTeam,
  deleteMember,
  deleteTeam,
  extendDeadLineRequest,
  getTeamById,
  getTeamMemberRolesPermission,
  getTeamMembers,
  getTeamMembersRoles,
  getTeamMembersToAssign,
  getTeams,
  getUsersForNonTeamMembers,
  getUsersForTransferTeams,
  sendMessageToLeads,
  sendMessageToMembers,
  transferTeamOwnerShip,
  unAssignCourse,
  updateCourseDeadline,
  updateCourseDeadlinePayload,
  updateTeam,
  updateTeamMember,
  validateManageTeamLink,
  validateUserBeforeTeamDetailPage,
  TeamsAvailableBundleRequestType,
  GetTeamsAvailableBundleList,
  GetTeamMembersToAssignBundlePayload,
  getTeamMembersToAssignBundle,
  AssignBundlePayload,
  assignBundle,
  TeamReportRequestType,
  TeamReportResponseType,
  teamReport,
  GetAvailablePreDefinedCourses,
  savePreDefinedCourses,
  SavePreDefinedCoursesRequestType,
  getPreDefinedCourseOverview,
  GetPreDefinedCourseOverviewRequestType,
  assignPreDefinedCourses,
  AssignPreDefinedCoursesRequestType,
  getPreDefinedAssignedCourseMemberWise,
  PreDefinedAssignedCourseMemberWiseRequestType,
  unAssignPreDefinedCourses,
  UnAssignPreDefinedCoursesRequestType,
  updateTimeFrame,
  UpdateTimeFrameRequestType,
  deletePreDefinedCourses,
  DeletePreDefinedCoursesRequestType,
} from "../service/teamsServices";
import { TAP_USER_ROLES } from "../../../core/models/enums/tap.enum";
import { useParams } from "react-router-dom";
import { setGlobalState } from "../../../../store/slice/GlobalSlice";
import { NOTIFICATION_CONST } from "../../../core/models/constants/core.constants";

export const useValidateUserBeforeTeamDetailPage = (
  options?: MutateOptions<
    GenericResponse<boolean>,
    AxiosError,
    ValidateUserBeforeTeamDetailPagePayload
  >
) => {
  return useMutation({
    mutationFn: validateUserBeforeTeamDetailPage,
    ...options,
    onSuccess: (data, vars, context) => {
      if (options?.onSuccess) {
        options.onSuccess(data, vars, context);
      }
    },
    onError: (error, vars, context) => {
      if (options?.onError) {
        options.onError(error, vars, context);
      }
    },
  });
};

export const VALIDATE_MANAGE_TEAM_LINK_IN_HEADER_QUERY_KEY =
  "VALIDATE_MANAGE_TEAM_LINK_IN_HEADER_QUERY_KEY";
export const useValidateManageTeamLink = (token: { token: string }) => {
  const { role } = useSelector((state: RootState) => state.userDetail);
  const resp = useQuery({
    queryKey: [VALIDATE_MANAGE_TEAM_LINK_IN_HEADER_QUERY_KEY],
    queryFn: async () => {
      if (role === TAP_USER_ROLES.CORPORATE_USER) {
        const response = await validateManageTeamLink();
        return response;
      }
      return false;
    },
    staleTime: 6000 * 60,
    enabled: !!token,
  });
  return resp;
};

export const GET_TEAM_MEMBER_ASSIGNED_COURSES_LIST_QUERY_KEY =
  "GET_TEAM_MEMBER_ASSIGNED_COURSES_LIST_QUERY_KEY";

export const useGetTeamMemberAssignedCoursesList = (
  params: GetAssignedCoursesListOfTeamMemberType
) => {
  const resp = useQuery({
    queryKey: [GET_TEAM_MEMBER_ASSIGNED_COURSES_LIST_QUERY_KEY, params],
    queryFn: async () => {
      const response = await GetAssignedCoursesListOfTeamMember(params);
      return response;
    },
  });
  return resp;
};

export const GET_TEAM_MEMBER_COURSES_LIST_QUERY_KEY =
  "GET_TEAM_MEMBER_COURSES_LIST_QUERY_KEY";

export const useGetTeamMemberCoursesList = (
  params: GetAssignedCoursesListOfTeamMemberType
) => {
  const resp = useQuery({
    queryKey: [GET_TEAM_MEMBER_COURSES_LIST_QUERY_KEY, params],
    queryFn: async () => {
      const response = await GetCoursesListOfMember(params);
      return response;
    },
  });
  return resp;
};

export const GET_TEAMS_COURSES_LIST_QUERY_KEY =
  "GET_TEAMS_COURSES_LIST_QUERY_KEY";

export const useGetTeamsAvailableCoursesList = (
  params: TeamsAvailableCoursesRequestType
) => {
  const resp = useQuery({
    queryKey: [GET_TEAMS_COURSES_LIST_QUERY_KEY, params],
    queryFn: async () => {
      const response = await GetTeamsAvailableCoursesList(params);
      return response;
    },
  });
  return resp;
};

export const GET_TEAMS_AVAILABLE_BUNDLE_LIST =
  "GET_TEAMS_AVAILABLE_BUNDLE_LIST";

export const useGetTeamsAvailableBundleList = (
  params: TeamsAvailableBundleRequestType
) => {
  const resp = useQuery({
    queryKey: [GET_TEAMS_AVAILABLE_BUNDLE_LIST, params],
    queryFn: async () => {
      const response = await GetTeamsAvailableBundleList(params);
      return response;
    },
  });
  return resp;
};

export const GET_TEAMS_ASSIGNED_COURSES_LIST_QUERY_KEY =
  "GET_TEAMS_ASSIGNED_COURSES_LIST_QUERY_KEY";

export const useGetTeamsAssignedCoursesList = (
  params: GetTeamsAssignedCoursesListParamsType
) => {
  const resp = useQuery({
    queryKey: [GET_TEAMS_ASSIGNED_COURSES_LIST_QUERY_KEY, params],
    queryFn: async () => {
      const response = await GetTeamsAssignedCoursesList(params);
      return response;
    },
  });
  return resp;
};

export const GET_TEAMS_MEMBER_LIST_QUERY_KEY =
  "GET_TEAMS_MEMBER_LIST_QUERY_KEY";

export const useGetTeamsMemberList = (params: GetTeamsMemberListParamsType) => {
  const resp = useQuery({
    queryKey: [GET_TEAMS_MEMBER_LIST_QUERY_KEY, params],
    queryFn: async () => {
      const response = await GetTeamsMemberList(params);
      return response;
    },
  });
  return resp;
};

export const GET_TEAMS_ROLE_TYPE_KEY = "GET_TEAMS_ROLE_TYPE_KEY";
export const useGetTeamMembersRoles = () => {
  const resp = useQuery({
    queryKey: [GET_TEAMS_ROLE_TYPE_KEY],
    queryFn: async () => {
      const response = await getTeamMembersRoles();

      const teamRoleDropdownValues: SCSelectType[] | undefined = response
        ? response?.map((roleType) => ({
            value: roleType.teamsRoleTypeId,
            label: roleType.teamRoleTypeDescription,
          }))
        : undefined;

      return teamRoleDropdownValues;
    },
    staleTime: 60 * 60000,
  });

  return resp;
};

export const GET_TEAM_MEMBERS_KEY = "GET_TEAMS_ROLE_TYPE_KEY";
export const useGetTeamMembers = (params?: GetTeamMembersPayload) => {
  const resp = useQuery({
    queryKey: [GET_TEAMS_ROLE_TYPE_KEY, params],
    queryFn: async () => {
      const response = await getTeamMembers(params);
      return response;
    },
  });

  return resp;
};

export const useCreateTeam = (
  options?: MutateOptions<
    GenericResponse<string>,
    AxiosError,
    CreateEditTeamPayload
  >
) => {
  return useMutation({
    mutationFn: createTeam,
    ...options,
    onSuccess: (data, vars, context) => {
      if (options?.onSuccess) {
        options.onSuccess(data, vars, context);
      }
    },
    onError: (error, vars, context) => {
      if (options?.onError) {
        options.onError(error, vars, context);
      }
    },
  });
};

export const useUpdateTeam = (
  options?: MutateOptions<
    GenericResponse<void>,
    AxiosError,
    CreateEditTeamPayload
  >
) => {
  return useMutation({
    mutationFn: updateTeam,
    ...options,
    onSuccess: (data, vars, context) => {
      if (options?.onSuccess) {
        options.onSuccess(data, vars, context);
      }
    },
    onError: (error, vars, context) => {
      if (options?.onError) {
        options.onError(error, vars, context);
      }
    },
  });
};

export const useAddTeamMember = (
  options?: MutateOptions<
    GenericResponse<void>,
    AxiosError,
    AddTeamMemberPayload
  >
) => {
  return useMutation({
    mutationFn: addTeamMember,
    ...options,
    onSuccess: (data, vars, context) => {
      if (options?.onSuccess) {
        options.onSuccess(data, vars, context);
      }
    },
    onError: (error, vars, context) => {
      if (options?.onError) {
        options.onError(error, vars, context);
      }
    },
  });
};

export const useUpdateCourseDeadline = (
  options?: MutateOptions<
    GenericResponse<number>,
    AxiosError,
    updateCourseDeadlinePayload
  >
) => {
  return useMutation({
    mutationFn: updateCourseDeadline,
    ...options,
    onSuccess: (data, vars, context) => {
      if (options?.onSuccess) {
        options.onSuccess(data, vars, context);
      }
    },
    onError: (error, vars, context) => {
      if (options?.onError) {
        options.onError(error, vars, context);
      }
    },
  });
};

export const GET_TEAMS_QUERY_KEY = "GET_TEAMS_QUERY_KEY";
export const useGetTeams = (params: GetTeamsPayload) => {
  const resp = useQuery({
    queryKey: [GET_TEAMS_QUERY_KEY, params],
    queryFn: async () => {
      const response = await getTeams(params);
      return response;
    },
    refetchOnWindowFocus: false,
  });

  return resp;
};

export const useGetTeamsOnScroll = (
  options?: MutateOptions<
    GenericResponse<GetTeamsResponse>,
    AxiosError,
    GetTeamsPayload
  >
) => {
  return useMutation({
    mutationFn: getTeams,
    ...options,
    onSuccess: (data, vars, context) => {
      if (options?.onSuccess) {
        options.onSuccess(data, vars, context);
      }
    },
    onError: (error, vars, context) => {
      if (options?.onError) {
        options.onError(error, vars, context);
      }
      message.error(error.message);
    },
  });
};

export const GET_TEAM_BY_ID_QUERY_KEY = "GET_TEAM_BY_ID_QUERY_KEY";
export const useGetTeamById = (params: GetTeamByIdPayload) => {
  const resp = useQuery({
    queryKey: [GET_TEAM_BY_ID_QUERY_KEY, params],
    queryFn: async () => {
      const response = await getTeamById(params);
      return response;
    },
    enabled: !!params.teamKey,
    staleTime: 60000 * 5,
  });

  return resp;
};

export const useDeleteTeam = (
  options?: MutateOptions<GenericResponse<void>, AxiosError, DeleteTeamPayload>
) => {
  return useMutation({
    mutationFn: deleteTeam,
    ...options,
    onSuccess: (data, vars, context) => {
      if (options?.onSuccess) {
        options.onSuccess(data, vars, context);
      }
    },
    onError: (error, vars, context) => {
      if (options?.onError) {
        options.onError(error, vars, context);
      }
    },
  });
};

export const useDeleteMember = (
  options?: MutateOptions<
    GenericResponse<DeleteMemberResponse>,
    AxiosError,
    DeleteMemberPayload
  >
) => {
  return useMutation({
    mutationFn: deleteMember,
    ...options,
    onSuccess: (data, vars, context) => {
      if (options?.onSuccess) {
        options.onSuccess(data, vars, context);
      }
    },
    onError: (error, vars, context) => {
      if (options?.onError) {
        options.onError(error, vars, context);
      }
    },
  });
};

export const useGetTeamMembersToAssign = (
  options?: MutateOptions<
    GenericResponse<GetTeamMembersToAssignResponse>,
    AxiosError,
    GetTeamMembersToAssignPayload
  >
) => {
  return useMutation({
    mutationFn: getTeamMembersToAssign,
    ...options,
    onSuccess: (data, vars, context) => {
      if (options?.onSuccess) {
        options.onSuccess(data, vars, context);
      }
    },
    onError: (error, vars, context) => {
      if (options?.onError) {
        options.onError(error, vars, context);
      }
    },
  });
};

export const GET_TEAM_MEMBERS_TO_ASSIGN_BUNDLE =
  "GET_TEAM_MEMBERS_TO_ASSIGN_BUNDLE";
export const useGetTeamMembersToAssignBundle = (
  params: GetTeamMembersToAssignBundlePayload
) => {
  const resp = useQuery({
    queryKey: [GET_TEAM_MEMBERS_TO_ASSIGN_BUNDLE, params],
    queryFn: async () => {
      const response = await getTeamMembersToAssignBundle(params);
      return response;
    },
    refetchOnWindowFocus: false,
  });

  return resp;
};

export const useAssignCourse = (
  options?: MutateOptions<
    GenericResponse<void>,
    AxiosError,
    AssignCoursePayload
  >
) => {
  return useMutation({
    mutationFn: assignCourse,
    ...options,
    onSuccess: (data, vars, context) => {
      if (options?.onSuccess) {
        options.onSuccess(data, vars, context);
      }
    },
    onError: (error, vars, context) => {
      if (options?.onError) {
        options.onError(error, vars, context);
      }
    },
  });
};

export const useUnAssignCourse = (
  options?: MutateOptions<
    GenericResponse<void>,
    AxiosError,
    UnAssignCoursePayload
  >
) => {
  return useMutation({
    mutationFn: unAssignCourse,
    ...options,
    onSuccess: (data, vars, context) => {
      if (options?.onSuccess) {
        options.onSuccess(data, vars, context);
      }
    },
    onError: (error, vars, context) => {
      if (options?.onError) {
        options.onError(error, vars, context);
      }
    },
  });
};

export const useAssignBundle = (
  options?: MutateOptions<
    GenericResponse<void>,
    AxiosError,
    AssignBundlePayload
  >
) => {
  return useMutation({
    mutationFn: assignBundle,
    ...options,
    onSuccess: (data, vars, context) => {
      if (options?.onSuccess) {
        options.onSuccess(data, vars, context);
      }
    },
    onError: (error, vars, context) => {
      if (options?.onError) {
        options.onError(error, vars, context);
      }
    },
  });
};

export const GET_TEAM_MEMBER_ROLES_PERMISSION_QUERY_KEY =
  "GET_TEAM_MEMBER_ROLES_PERMISSION_QUERY_KEY";
export const useGetTeamMemberRolesPermission = (
  params: GetTeamMemberRolesPermissionPayload
) => {
  const resp = useQuery({
    queryKey: [GET_TEAM_MEMBER_ROLES_PERMISSION_QUERY_KEY, params],
    queryFn: async () => {
      const response = await getTeamMemberRolesPermission(params);
      return response;
    },
    enabled: !!params.teamKey,
    staleTime: 60000 * 60,
  });

  return resp;
};

export const GET_USERS_FOR_NON_TEAM_MEMBERS_QUERY_KEY =
  "GET_USERS_FOR_NON_TEAM_MEMBERS_QUERY_KEY";
export const useGetUsersForNonTeamMembers = (
  params: GetUsersForNonTeamMembersPayload
) => {
  const resp = useQuery({
    queryKey: [GET_USERS_FOR_NON_TEAM_MEMBERS_QUERY_KEY, params],
    queryFn: async () => {
      const response = await getUsersForNonTeamMembers(params);
      return response;
    },
    enabled: !!params.teamKey,
  });

  return resp;
};

export const GET_USERS_FOR_TRANSFER_TEAMS =
  "GET_USERS_FOR_TRANSFER_TEAMS_QUERY_KEY";
export const useGetUsersForTransferTeams = (
  params: GetUsersForTransferTeamsPayload
) => {
  const resp = useQuery({
    queryKey: [GET_USERS_FOR_TRANSFER_TEAMS, params],
    queryFn: async () => {
      const response = await getUsersForTransferTeams(params);

      const usersForTransferTeamsDropdownValues: SCSelectType[] | undefined =
        response
          ? response.result?.map((roleType) => ({
              value: roleType.userId,
              label:
                roleType.userName +
                " | " +
                (!roleType.jobTitle ? "N/A" : roleType.jobTitle),
            }))
          : undefined;

      return usersForTransferTeamsDropdownValues;
    },
  });

  return resp;
};

export const useTransferTeamOwnership = (
  options?: MutateOptions<
    GenericResponse<void>,
    AxiosError,
    TransferTeamOwnerShip
  >
) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: transferTeamOwnerShip,
    ...options,
    onSuccess: (data, vars, context) => {
      if (options?.onSuccess) {
        options.onSuccess(data, vars, context);
      }
      queryClient.invalidateQueries({
        queryKey: [GET_TEAMS_QUERY_KEY],
      });
    },
    onError: (error, vars, context) => {
      if (options?.onError) {
        options.onError(error, vars, context);
      }
    },
  });
};

export const useExtendDeadLineRequest = (
  options?: MutateOptions<
    GenericResponse<void>,
    AxiosError,
    IExtendDeadLineRequest
  >
) => {
  return useMutation({
    mutationFn: extendDeadLineRequest,
    ...options,
    onSuccess: (data, vars, context) => {
      if (options?.onSuccess) {
        options.onSuccess(data, vars, context);
      }
    },
    onError: (error, vars, context) => {
      if (options?.onError) {
        options.onError(error, vars, context);
      }
    },
  });
};

export const useSendMessageToLeads = (
  options?: MutateOptions<
    GenericResponse<void>,
    AxiosError,
    ISendMessageToLeads
  >
) => {
  return useMutation({
    mutationFn: sendMessageToLeads,
    ...options,
    onSuccess: (data, vars, context) => {
      if (options?.onSuccess) {
        options.onSuccess(data, vars, context);
      }
    },
    onError: (error, vars, context) => {
      if (options?.onError) {
        options.onError(error, vars, context);
      }
    },
  });
};

export const useSendMessageToMembers = (
  options?: MutateOptions<
    GenericResponse<void>,
    AxiosError,
    ISendMessageToMembers
  >
) => {
  return useMutation({
    mutationFn: sendMessageToMembers,
    ...options,
    onSuccess: (data, vars, context) => {
      if (options?.onSuccess) {
        options.onSuccess(data, vars, context);
      }
    },
    onError: (error, vars, context) => {
      if (options?.onError) {
        options.onError(error, vars, context);
      }
    },
  });
};

export const useUpdateTeamMember = (
  options?: MutateOptions<
    GenericResponse<void>,
    AxiosError,
    EditTeamMemberPayload
  >
) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: updateTeamMember,
    ...options,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [GET_TEAMS_MEMBER_LIST_QUERY_KEY],
      });
    },
    onError: (error, vars, context) => {
      if (options?.onError) {
        options.onError(error, vars, context);
      }
    },
  });
};

export const GET_TEAM_WORKFORCE_LIST_QUERY_KEY =
  "GET_TEAM_WORKFORCE_LIST_QUERY_KEY";
export const useGetTeamWorkforceTableList = (
  params: GetTeamWorkForceListParamsType
) => {
  const resp = useQuery({
    queryKey: [GET_TEAM_WORKFORCE_LIST_QUERY_KEY, params],
    queryFn: async () => {
      const response = await GetTeamWorkForceList(params);
      if (response) {
        const updatedItems = response?.workForceUsers?.map((list, index) => ({
          ...list,
          key: index.toString(),
        }));
        response.workForceUsers = updatedItems;
      }

      return response;
    },
  });

  return resp;
};

export const useTeamReportDownload = (
  options?: MutateOptions<
    GenericResponse<TeamReportResponseType>,
    AxiosError,
    TeamReportRequestType
  >
) => {
  return useMutation({
    mutationFn: teamReport,
    ...options,
    onSuccess: (data, vars, context) => {
      if (options?.onSuccess) {
        options.onSuccess(data, vars, context);
      }
    },
    onError: (error, vars, context) => {
      if (options?.onError) {
        options.onError(error, vars, context);
      }
    },
  });
};

export const GET_AVAILABLE_PREDEFINED_COURSES_QUERY_KEY =
  "GET_AVAILABLE_PREDEFINED_COURSES_QUERY_KEY";
export const DEFAULT_PAGE_SIZE_FOR_COURSES = 20;
export const useGetAvailablePreDefinedCourses = (
  searchParams = "",
  pageSize = DEFAULT_PAGE_SIZE_FOR_COURSES
) => {
  const dispatch = useDispatch();
  const { id: teamKey } = useParams();
  const resp = useInfiniteQuery({
    queryKey: [
      GET_AVAILABLE_PREDEFINED_COURSES_QUERY_KEY,
      { pageSize, searchParams },
    ],
    queryFn: async ({ pageParam = 1 }) => {
      const response = await GetAvailablePreDefinedCourses({
        pageIndex: pageParam,
        pageSize: pageSize,
        teamKey: teamKey ?? "",
        courseName: searchParams,
      });
      if (response.isError) {
        dispatch(
          setGlobalState({
            error: {
              message:
                typeof response.errorMessage === "string"
                  ? response.errorMessage
                  : "",
              type: NOTIFICATION_CONST.ERROR,
            },
          })
        );
      } else {
        return response.result;
      }
    },
    initialPageParam: 1,
    getNextPageParam: (lastPage, allPages) => {
      return lastPage?.teamAvailablePreDefinedCourses.items.length
        ? allPages?.length + 1
        : undefined;
    },
    refetchOnWindowFocus: false,
  });
  return resp;
};

export const useSavePreDefinedCourses = (
  options?: MutateOptions<
    GenericResponse<void>,
    AxiosError,
    SavePreDefinedCoursesRequestType
  >
) => {
  return useMutation({
    mutationFn: savePreDefinedCourses,
    ...options,
    onSuccess: (data, vars, context) => {
      if (options?.onSuccess) {
        options.onSuccess(data, vars, context);
      }
    },
    onError: (error, vars, context) => {
      if (options?.onError) {
        options.onError(error, vars, context);
      }
    },
  });
};

export const GET_PREDEFINED_COURSES_OVERVIEW_QUERY_KEY =
  "GET_PREDEFINED_COURSES_OVERVIEW_QUERY_KEY";
export const useGetPreDefinedCourseOverview = (
  params: GetPreDefinedCourseOverviewRequestType
) => {
  const resp = useQuery({
    queryKey: [GET_PREDEFINED_COURSES_OVERVIEW_QUERY_KEY, params],
    queryFn: async () => {
      const response = await getPreDefinedCourseOverview(params);

      return response;
    },
    enabled: !!params.teamKey,
    refetchOnWindowFocus: false,
  });

  return resp;
};

export const useAssignPreDefinedCourses = (
  options?: MutateOptions<
    GenericResponse<void>,
    AxiosError,
    AssignPreDefinedCoursesRequestType
  >
) => {
  return useMutation({
    mutationFn: assignPreDefinedCourses,
    ...options,
    onSuccess: (data, vars, context) => {
      if (options?.onSuccess) {
        options.onSuccess(data, vars, context);
      }
    },
    onError: (error, vars, context) => {
      if (options?.onError) {
        options.onError(error, vars, context);
      }
    },
  });
};

export const GET_PREDEFINED_ASSIGNED_COURSES_MEMBER_WISE_OVERVIEW_QUERY_KEY =
  "GET_PREDEFINED_ASSIGNED_COURSES_MEMBER_WISE_OVERVIEW_QUERY_KEY";
export const useGetPreDefinedAssignedCourseMemberWise = (
  params: PreDefinedAssignedCourseMemberWiseRequestType
) => {
  const resp = useQuery({
    queryKey: [
      GET_PREDEFINED_ASSIGNED_COURSES_MEMBER_WISE_OVERVIEW_QUERY_KEY,
      params,
    ],
    queryFn: async () => {
      const response = await getPreDefinedAssignedCourseMemberWise(params);

      return response;
    },
    enabled: !!params.teamKey,
    refetchOnWindowFocus: false,
  });

  return resp;
};

export const useUnAssignPreDefinedCourses = (
  options?: MutateOptions<
    GenericResponse<void>,
    AxiosError,
    UnAssignPreDefinedCoursesRequestType
  >
) => {
  return useMutation({
    mutationFn: unAssignPreDefinedCourses,
    ...options,
    onSuccess: (data, vars, context) => {
      if (options?.onSuccess) {
        options.onSuccess(data, vars, context);
      }
    },
    onError: (error, vars, context) => {
      if (options?.onError) {
        options.onError(error, vars, context);
      }
    },
  });
};

export const useUpdateTimeFrame = (
  options?: MutateOptions<
    GenericResponse<void>,
    AxiosError,
    UpdateTimeFrameRequestType
  >
) => {
  return useMutation({
    mutationFn: updateTimeFrame,
    ...options,
    onSuccess: (data, vars, context) => {
      if (options?.onSuccess) {
        options.onSuccess(data, vars, context);
      }
    },
    onError: (error, vars, context) => {
      if (options?.onError) {
        options.onError(error, vars, context);
      }
    },
  });
};

export const useDeletePreDefinedCourses = (
  options?: MutateOptions<
    GenericResponse<void>,
    AxiosError,
    DeletePreDefinedCoursesRequestType
  >
) => {
  return useMutation({
    mutationFn: deletePreDefinedCourses,
    ...options,
    onSuccess: (data, vars, context) => {
      if (options?.onSuccess) {
        options.onSuccess(data, vars, context);
      }
    },
    onError: (error, vars, context) => {
      if (options?.onError) {
        options.onError(error, vars, context);
      }
    },
  });
};
