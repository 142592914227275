// TODO this file name should be changed and content can be optimized
export enum API_URLS {
  // Base
  API = "api/",
  BASE_SYSTEM_CONFIG = "api/SystemConfiguration/",
  BASE_USER = "api/user/",
  BASE_COURSE = "api/Course/",
  BASE_DOCUMENT = "api/Document/Upload/",
  BASE_DOCUMENT_LIST = "api/Document/",
  DASHBOARD = "api/dashboard/",
  BASE_HIGHSCHOOL = "api/highschool/",
  BASE_TERTIARY = "api/Tertiary/",
  BASE_SHOPPING = "api/Shopping/",
  BASE_PAYMENT = "api/Payment/",
  BASE_COMPANY_PROFILE = "api/CompanyProfile/",
  BASE_WORK_EXEPERIENCE = "api/WorkExperience/",
  BASE_REFERENCE = "api/Reference/",
  BASE_CLIENT = "api/Client/",
  BASE_SYSTEM_CONFIGURATION = "api/SystemConfiguration/",
  BASE_WORKFORCE_PROFILE = "api/WorkforceProfile/",
  BASE_TEAMS = "api/teams/",
  BASE_TEAMS_MEMBER = "api/TeamMember/",
  BASE_TEAMS_LEADERBOARD = "api/TeamLeaderboard",
  BASE_GET_TEAM_LEADERBOARDLIST = "/GetTeamLeaderboardList",
  BASE_BROADCASTS = "api/Broadcast/",
  BASE_BANNERS = "api/Showcase/",
  BASE_GET_TOP3_TEAM_LEADERBOARD = "/GetTop3TeamLeaderboard?teamKey=",
  BASE_DOWNLOAD_TEAM_LEADERBOARD_EXCEL = "/DownloadTeamLeaderboardExcel?teamKey=",
  BASE_CERTIFICATE_SIGNATURE = "api/CertificateSignature",

  // common
  GET_CONFIG_DATE_ITEMS = "GetConfigDataItems",
  GET_OFO_CODE_ITEMS = "GetOFOCodeItems",

  // Course
  GET_AVAILABLE_COURSES = "GetAvailableCourses",
  GET_MY_COURSES = "GetMyCourses?courseStatusType=",
  GET_E_LEARNING = "GetElearning",
  DELETE_USER_COURSE = "DeleteUserCourse?userCourseKey=",
  ABANDON_COURSE = "AbandonCourse?userCourseKey=",
  EXTEND_COURSE_EXPIRY = "ExtendExpiry?userCourseKey=",
  UPDATE_RATING = "UpdateRating",
  UPDATE_PERSONAL_DEADLINE = "UpdatePersonalDeadline",
  START_COURSE_NAVIGATE_TO_MOODLE = "GetCourseUrl?courseId=",
  UPDATE_WISHLIST = "UpdateUserWishList?courseId=",
  GET_WISHLIST = "GetUserWishList",
  GET_TAG_LIST_FILTER = "GetCourseTagTypeList",
  GET_TYPE_LIST_FILTER = "GetTypeFilterList",
  GET_LEVEL_LIST_FILTER = "GetLevelFilterList",
  GET_PRICE_LIST_FILTER = "GetPriceFilter",
  GET_PRICE_RANGE_FILTER = "GetPriceRangeFilter",
  GET_DURATION_RANGE_FILTER = "GetDurationRangeFilter",
  GET_STORE_DATA = "GetStoreData",
  GET_COURSES_FROM_BUNDLE = "GetCourseBundle",
  GET_CERTIFICATE = "GetCertificate",

  // Common
  PAGESIZE = "&pageSize=",
  GETCONFIGDATAITEMS = "GetConfigDataItems",

  // Auth
  LOGIN = "login",
  LOGOUT = "logout",
  SIGNUP = "signup",
  REGISTER = "register",
  SENDOTP = "SendOtp",
  FORGETPASSWORD = "forgetpassword",
  CHANGEPASSWORD = "changepassword",
  VALIDATEOTP = "validateOtp",
  VALIDATE_SIGNUP_FORM = "ValidateRegister",
  RESET_PASSWORD = "resetPassword",
  HAND_SHAKE = "refreshtoken",
  CORPORATE_USER_INQUIRY = "Inquiry",
  VALIDATE_INQUARY = "validateInquiry",
  CREATE_PASSWORD_OTP = "CreatePasswordOTP",
  CREATE_PASSWORD = "createpassword",
  VALIDATE_TOKEN = "ValidateToken",

  // Dashboard
  GET_NOTIFICATIONS = "GetNotifications?page=",
  MARK_NOTIFICATION_AS_READ = "MarkNotifactionAsRead/",
  GET_MARKETING_LIST = "GetMarketingList?page=",
  GET_LEADER_LIST = "GetLeaderList?page=",
  GET_CLIENT_BROADCAST_LIST = "GetClientBroadcast?page=",
  GET_CLIENT_BANNER_LIST = "GetClientBanner",

  // Other
  HEALTH_CHECK = "HealthCheck",
  TERMS_AND_CONDITION = "termsandcondition/termsandcondition",
  PRIVACY_POLICY = "termsandcondition/privacypolicy",
  GET_ABOUT_US = "GetAboutUs",
  GET_HEAR_ABOUT_US_LIST = "GetHearAboutUsList",
  GET_HELP_AND_SUPPORT = "GetHelpAndSupport",
  CREATE_HELP_AND_SUPPORT_REQUEST = "CreateHelpAndSupportRequest",
  GET_INDUSTRY_LIST = "GetIndustryList",

  // Profile
  USERDETAIL = "UserDetail",
  USERDOCUMENTS = "GetAllDocumentList",
  COUNTRY = "Country",
  GETLANGUAGELIST = "Language/GetLanguageList",
  CHANGEUSERPASSWORD = "changeUserPassword",
  CHANGEUSERNAME = "ChangeUsername",
  UPDATEPHONENO = "UpdatePhoneNo",
  DOWNLOADUSERDETAIL = "DownloadUserDetail",
  DEACTIVEPROFILE = "DeactiveProfile",
  SENDCHANGEEMAILOTP = "SendChangeEmailOTP",
  REACTIVATE = "reactivate",
  CHANGEEMAIL = "ChangeEmail",
  UPDATEPROFILE = "UpdateProfile",
  UPDATEFULLPROFILE = "updateUserProfile",
  GETDISABILITYLIST = "disability/GetDisabilityList",
  GETDISABILITYTYPELIST = "disability/GetDisabilityTypeList",
  GETDEMOGRAPHICLIST = "GetDemographicList",
  UPDATE_USER_SELECTION = "api/user/updateUserSelection",
  GET_GENDER_LIST = "GetGenderList",
  GET_MIGRATION_CHECK = "GetMigrationCheck",
  MIGRATE_USER_DATA = "MigrateStudent",
  GET_OFO_GROUP_DETAIL = "GetOFOGroupDetail?OFOCode=",
  SEND_CHANGE_REQUEST_MAIL = "SendChangeRequestMail",
  UPDATE_EMAIL_SETTING = "UpdateEmailSetting ",
  DOWNLOAD_CV_DOCUMENTS = "api/user/DownloadUserCV?userCVTemplateEnum=",

  // High School
  DELETE_HIGHSCHOOL = "highschool/DeleteUserHighSchool?userHighSchoolKey=",
  SAVEHIGHSCHOOL = "highschool/save",
  HIGHSCHOOLLIST = "highschool/GetUserHighSchoolList",
  MY_HIGH_SCHOOL = "GetUserHighSchoolList",
  HIGHSCHOOL = "highschool",
  GRADELIST = "/GradeList",
  SUBJECTLIST = "/SubjectList",

  // Tertiary API
  MY_TERTIART_QUALIFICATION_LIST = "GetUserTertiaryList",
  ADD_TERTIART_QUALIFICATION = "Save",
  EDIT_TERTIART_QUALIFICATION = "update",
  DELETE_TERTIART_QUALIFICATION = "DeleteUserTertiary?userTertiaryKey=",
  GET_INSTITUDE_LIST = "GetTertiaryInstitutionList",
  GET_TYPE_OF_INSTITUTE_LIST = "GetTertiaryTypeList",
  GET_QUALIFICATION_LIST = "GetTertiaryQualificationList",
  GET_TYPE_OF_QUALIFICATION_LIST = "GetTertiaryQualificationTypeList",
  GET_TERTIARY_STATUS_LIST = "GetTertiaryCompleteTypeList",

  // Completed course API
  GET_COURSE_DETAIL = "GetCourseDetail?courseKey=",
  GET_USER_COURSE_LIST = "GetUserCourseList",
  ADD_USER_COURSE_LIST = "Save",
  DELETE_USER_COURSE_LIST = "DeleteUserCourse?userCourseKey=",
  GET_TRAINING_PROVIDER_LIST = "GetTrainingProviderList",

  // Skills API
  SAVE_SKILL = "skill/Save",
  DELETE_SKILL = "Skill/DeleteUserSkill?skillId=",
  GET_SKILL_LIST = "skill",
  GET_PROFFICIANCY_LEVEL_LIST = "skill/GetProfficiancyLevelList",
  GET_USER_SKILL_LIST = "skill/GetUserSkillList",

  // Work experience API
  GET_USER_WORK_EXPERIENCE_LIST = "GetUserWorkExperienceList",
  SAVE_WORK_EXPERIENCE = "Save",
  EDIT_WORK_EXPERIENCE = "Save",
  DELETE_WORK_EXPERIENCE = "DeleteUserWorkExperience?userWorkExperienceKey=",
  GET_USER_COMPANY_LIST = "GetUserCompany",
  GET_USER_REFERENCE_LIST = "GetUserReferenceList",
  SAVE_REFERENCE = "Save",
  EDIT_REFERENCE = "Save",
  DELETE_REFERENCE = "DeleteUserReference?userReferenceKey=",
  GET_JOB_TITLE_LIST = "GetJobTitle",
  GET_EMPLOYMENT_TYPE = "GetEmploymentType",
  GET_GROUP_DATA_LIST = "GetWorkExperienceGroup",
  GET_LOCATION_LIST = "GetLocation",
  GET_COMPANY_LIST = "GetUserCompany",

  GET_DOWNLOAD_APP = "CreateDownloadAppRequest?email=",

  // Shopping
  GET_SHOPPING_CART = "GetShoppingCart",
  ADD_TO_CART = "Addtocart",
  DELETE_ITEM = "DeleteItem?userCartId=",
  CLEAR_CART = "ClearCart",
  GET_CART_DETAILS = "GetCartDetails",
  SELECT_CART_ITEM = "selectCartItem",

  // Billing
  GET_PAYMENT_HISTORY = "GetPaymentHistory",
  MAKE_PAYMENT_TRANSACTION = "makePaymentTransaction",
  SAVE_TRANSECTION_DETAIL = "SaveTransactionDetail",
  GET_INVOICE = "GetCourseInvoice",

  // Company profile
  ADD_COMPANY_PROFILE = "CreateAndUpdateCompanyProfile",
  GET_COMPANY_PROFILE = "GetCompanyProfile",
  GET_WORK_FORCE_PROFILE_USERS = "getWorkForceProfileUsers",
  GET_STAFF_COUNT_BY_EMPLOYMENT_TYPE = "GetStaffCountByEmploymentType",
  GET_WORK_FORCE_PROFILE_USERS_BY_ID = "GetWorkForceProfileUsersById",
  GET_INDUSTRY_AMENDMENTS = "GetIndustryAmendments",

  // Document
  GET_All_DOCUMENT_LIST = "GetAllDocumentsGroupByList",
  DELETE_DOCUMENT = "DeleteDocument",
  DownloadAll = "DownloadAll",

  // Workforce Profile
  DELETE_EMPLOYEE = "Delete?workforceProfileKey=",
  CreateWorkForceProfile = "CreateWorkForceProfile",
  UpdateWorkForceProfile = "UpdateWorkForceProfile",
  SendMailToEmployee = "sendMailToEmployee",
  UpdateTerminationStatus = "UpdateTerminationStatus",
  GetWorkForceUsersCourseDetails = "GetWorkForceUsersCourseDetails",

  BasicBulkUpload = "BasicBulkUpload",
  AdvancedBulkUpload = "AdvancedBulkUpload",
  GetUserDetailsByEmail = "GetUserDetailsByEmail",
  GetProvinces = "GetProvinces",
  GETLIST = "GetList",
  DownloadWorkforceProfileExcel = "DownloadWorkforceProfileExcel",

  // theme
  THEME = "api/CompanyThemeSettings/get",
  THEME_UPDATE = "api/CompanyThemeSettings/update",
  THEME_RESET = "api/CompanyThemeSettings/reset",
  GET_BROADCAST_LIST = "api/Broadcast/GetBroadcastList",
  GET_BROADCAST_BY_ID = "api/Broadcast/GetBroadcastByID",
  GET_BANNER_BY_ID = "api/Showcase/GetShowcaseByID",
  GET_BANNER_LIST = "api/Showcase/GetShowcaseList",

  Broadcast = "Broadcast",

  // SMTP Connection
  TEST_SMTP_CONNECTION = "api/Client/TestSmtpConnection",
  SAVE_SMTP_CONNECTION = "api/Client/SaveSmtpConnection",
  GET_SMTP_CONNECTION = "api/Client/GetSmtpConnection",
  DELETE_SMTP_CONNECTION = "api/Client/DeleteSmtpConnection",

  // Teams
  VALIDATE_USER_BEFORE_FETCHING_TEAM_DETAIL_ = "api/Teams/Validate",
  VALIDATE_MANAGE_TEAM_LINK_IN_HEADER = "api/Teams/ValidateManageTeamLink",
  GET_TEAMS_AVAILABLE_COURSES = "api/Teams/GetTeamsAvailableCourses",
  GET_TEAMS_AVAILABLE_BUNDLE = "api/Teams/GetAvailableCourseBundles",
  GET_TEAMS_ASSIGNED_COURSES = "api/Teams/GetAssignedCourseByTeamMember",
  GET_COURSE_LIST_BY_MEMBER = "api/TeamMember/GetTeamMemberCourses",
  GET_ASSIGNED_COURSE_LIST = "api/TeamMember/GetTeamMemberAssignedCourses",
  TEAM_OVERVIEW = "api/Teams/TeamOverview",
  REMOVE_TEAM_MEMBER = "api/TeamMember/Delete",
  UNASSIGN_COURSE = "api/Teams/UnAssignCourse",
  UPDATE_COURSE_DEADLINE = "api/Teams/UpdateDeadLine",
  TRANSFER = "api/Teams/Transfer",
  EXTEND_DEADLINE_REQUEST = "api/Teams/ExtendDeadLineRequest",
  SEND_MESSAGE_TO_LEADS = "api/Teams/SendMessageToLeads",
  SEND_MESSAGE_TO_MEMBERS = "api/Teams/SendMessageToMembers",
  GET_AVAILABLE_COURSE_LIST = "courses",
  GET_TEAM_MEMBERS = "GetTeamMembers",
  GET_TEAMS = "GetTeams",
  GET_TEAM_BY_ID = "GetTeamById",
  CREATE = "Create",
  GET_WORKFORCE_USERS_FOR_TEAM = "GetWorkForceUsersForTeam",

  //Team Member
  GET_TEAM_MEMBERS_ROLES = "GetTeamMembersRoles",
  ADD_MEMBER_IN_TEAM = "api/TeamMember/Create",
  UPDATE = "Update",
  DELETE = "Delete",
  GET_TEAM_MEMBERS_TO_ASSIGN = "GetTeamMembersToAssign",
  GET_TEAM_MEMBERS_TO_ASSIGN_BUNDLE = "GetTeamMemberToAssignBundle",
  ASSIGN_COURSE = "AssignCourse",
  ASSIGN_COURSE_BUNDLE = "AssignCourseBundle",
  GET_TEAM_MEMBER_ROLES_PERMISSION = "GetTeamMemberRolesPermission",
  GET_USERS_FOR_NON_TEAM_MEMBERS = "GetUsersForNonTeamMembers",
  GET_USERS_FOR_TRANSFER_TEAMS = "GetUsersForTransferTeams",
  TEAM_REPORT = "TeamReport",
  GET_AVAILABLE_PREDEFINED_COURSES = "GetAvailablePreDefinedCourses",
  SAVE_PREDEFINED_COURSES = "SavePreDefinedCourses",
  GET_PREDEFINED_COURSES_OVERVIEW = "GetPreDefinedCourseOverview",
  ASSIGN_PREDEFINED_COURSES = "AssignPreDefinedCourses",
  GET_PREDEFINED_ASSIGNED_COURSES_MEMBER_WISE = "GetPreDefinedAssignedCourseMemberWise",
  UNASSIGN_PREDEFINED_COURSES = "UnAssignPreDefinedCourses",
  DELETE_PREDEFINED_COURSES = "DeletePreDefinedCourses",
  UPDATE_TIME_FRAME = "UpdateTimeFrame",

  // Assignment of dashboard
  GET_ASSIGNMENT = "GetReportAssignment",
  ADD_DASHBOARD_TO_ROLE = "AddReportAssignment",

  // Email subscribe of dashboard
  GET_EMAIL_SUBSCRIPTION = "GetReportTimeSchedule",
  SET_EMAIL_SUBSCRIPTION = "AddReportTimeSchedule",

  UPDATE_REPORT_CONFIG = "CreateAndUpdateUserBasedReportConfiguration",
  FETCH_REPORT_CONFIG = "GetUserBasedReportConfiguration",

  //Academy
  GET_ACADEMY = "AcademyMaster/GetAll",
  GET_ACADEMY_BY_ID = "AcademyMaster/GetAcademyByMasterKey?academyMasterKey=",
  GET_GRADE = "TapSchooling/GetGrades",
  GET_SUBJECT_BY_GRADE = "TapSchooling/GetSubjectsByGrade?academyGradeKey=",
  STUDENT = "Student/",
  GET_STUDENT_HISTORY = "/GetStudentHistory",
  GET_STUDENT_GRADES = "/GetStudentGrades",
  GET_STUDENT_SUBJECT = "/GetStudentSubject",
  GRADE = "?grade=",
  GET_STUDENT_DETAIL = "/GetStudentDetails",
  GET_MODULE_BY_SUBJECT_KEY = "TapSchooling/GetModuleBySubjectKey?subjectKey=",
  ASSESSMENT = "Assessment/",
  GET_ASSESSMENT_QUESTION_COUNT_AND_LANGUAGES = "GetAssessmentQuestionCountandLanguages?academySubmoduleKey=",
  GET_ASSESSMENT_QUESTIONS = "GetAssessmentQuestions/",
  GET_STUDENT_GRADE_HISTORY = "/GetStudentGradeHistory",
  NUMBER_OF_QUESTIONS = "?numberOfQuestions=",
  GENERATE_ASSESSMENT_SCORE = "GenerateAssessmentScore",
  GET_ACADEMY_USER_ASSESSMENT = "GetAcademyUserAssessment/",
  ACADEMY_ANALYTICS = "AcademyAnalytics/",
  GET_ALL_SUBJECT_ANALYTICS = "GetAllSubjectAnalytics",
  DAY = "?day=",
  MONTH = "&month=",
  SUBJECT_KEY = "?subjectKey=",
  SUBMIT_ASSESSMENT_SCORE = "SubmitAssessmentScore",

  // Certificate Signature
  GetCertificateSignature = "api/CertificateSignature/Get",
  UpdateCertificateSignature = "api/CertificateSignature/Update",
  ResetCertificateSignature = "api/CertificateSignature/Reset",
}

export enum HTTP_STATUS_CODES {
  UNAUTHORIZED = 401,
}
