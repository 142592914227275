export class CoreConstants {
  static readonly APP_SECRET_KEY = "";
}

export const SOUTH_AFRICA_CUSTOM_COUNTRY_CODE = 163;

export const DATE_TIME_CONSTANTS = {
  LONG_DATE_TIME: "ddd, DD MMM YYYY",
  SC_LONG_DATE_TIME: "D MM YYYY h:mm A",
  SC_SHORT_MONTH_YEAR: "MMM YYYY",
  SC_LONG_DO_DATES: "D of MMMM YYYY",
  DATE_FORMAT: "YYYY-MM-DD",
  ONLY_YEAR: "YYYY",
  DAY_MONTH_YEAR: "DD/MM/YYYY",
};
export const SC_BUTTON_TYPE = {
  PRIMARY: "primary",
  EXPIRED: "expired",
  SECONDARY: "secondary",
  SUBMIT_PRIMARY: "submit_primary",
  UPLOAD_BUTTON: "uploadButton",
  SUBMIT_UPLOAD_BUTTON: "submit_uploadButton",
  KEYPAD: "keypad",
  TYPE_LINK: "TypeLink",
  CLEAR_FORM: "clear_form",
  PRICING_BACK: "pricing_back",
  UPLOAD_FILE_LABEL: "Upload Evidence",
};

export const VALIDATION_CONST = {
  Password_minimumLength: 8,
};
export const INPUT_ALLOWED_KEYS = [
  "Backspace",
  "ArrowLeft",
  "ArrowRight",
  "Tab",
  "Shift",
  "Alt",
  "Control",
  "End",
  "Home",
];
export const NOTIFICATION_CONST = {
  SUCCESS: "success",
  ERROR: "error",
  NETWORK_ERROR: "network",
};
export const ROUTES_CONST = {
  ROOT: "/",
  HEALTH: "health",
  PRIVACY_POLICY: "privacy-policy",
  TERMS_AND_CONDITION: "terms-&-condition",
  LOGIN: "login",
  SIGNUP: "signup",
  COMING_SOON: "coming-soon",
  FORGET_PASSWORD: "forget-password",
  CREATE_PASSWORD: "create-password",
  REACTIVATE: "reactivate",
  HOME_BASE: "/home",
  HOME: "/home/",
  DASHBOARD: "dashboard",
  DASHBOARD_REPORTING: "dashboard-reporting",
  MAP_REPORTING: "map-reporting",
  TEAMS: "my-teams",
  MANAGE_TEAMS: "manage-teams",
  ABOUT_US: "about-us",
  CONTACT_US: "contact-us",
  HELP_AND_SUPPORT: "help-and-support",
  MY_COURSES: "my-courses",
  MY_PROFILE: "my-profile",
  COMPANY_PROFILE: "company-profile",
  MY_SETTING: "my-setting",
  MY_SHOPPING_CART: "shopping-cart",
  CREATE_TEAM: "create-team",
  EDIT_TEAM: "edit-team",
  ASSIGN_PREDEFINED_COURSE: "assign-predefined-courses",
  CHECKOUT: "checkout",
  FEATURES: "features",
  DOWNLOAD_APP: "download-app",
  BILLING_DETAILS: "billing-details",
  STORE: "store",
  BUNDLE: "bundle",
  WISHLIST: "wishlist",
  PAYMENT_SUCCESS: "PaymentSuccess",
  PAYMENT_CANCEL: "PaymentCancel",
  ASTERISK_SYMBOL: "*",
  ASTERISK_SYMBOL_HOME: "/home/*",
  WORKFORCE_PROFILE: "workforce-profile",
  WORKFORCE_PROFILE_BULK_UPLOAD: "workforce-profile/bulk-upload",
  WORKFORCE_PROFILE_INDIVIDUAL_EMPLOYEE_ADD:
    "workforce-profile/individual-employee-add",
  THEME: "theme",
  ACADEMY: "academy",
  SET_STUDENT_PROFILE: "set-student-profile",
  ACADEMY_DASHBOARD: "academy-dashboard",
  ACADEMY_SUBJECT: "academy-subject",
  ACADEMY_ASSESMENT: "academy-assesment",
  ANALYTICS: "analytics",
  ASSESSMENT_SCORE: "assessment-score",
  ACADEMY_CHECKOUT: "academy-checkout",
  ACADEMY_PAYMENT_HISTORY: "academy-payment-history",
  TEAM_LEADERBOARD: "team-leaderboard",
};
export const CALCULATION_CONST = {
  KB: 1024,
  MB: 1048576,
};

export const PAGINATION_SETTING = {
  PAGE_SIZE: 10,
};

export const DOCUMENT_TYPES = {
  CURRICULUM_VITAE: "Curriculum Vitae",
  IDENTITY_DOCUMENT: "Identity Document",
  DRIVING_LICENSE_DOCUMENT: "Driving License Document",
  RESIDENT_DOCUMENT: "Resident Document",
  TERTIARY_QUALIFICATION: "Tertiary Qualification",
  PROFILE_PICTURE: "Profile Picture",
  HIGH_SCHOOL_CERTIFICATE: "High School Certificate",
  OTHER_COURSE: "Other Course",
  WORK_EXPERIENCE: "Work Experience",
  DISABLED_PROOF: "Disabled Proof",
  DTI_REGISTRATION_CERTIFICATE: "DTI Registration Certificate",
  TAX_CLEARANCE_CERTIFICATE: "Tax Clearance Certificate",
  CONFIRMATION_BANKING_DETAILS: "Confirmation of Banking Details",
  BBBEE_CERTIFICATE: "B-BBEE Certificate",
  SDF_LETTER_OF_APPOINTMENT: "SDF Letter of Appointment",
  SKILLS_EQUITY_COMMITTEE_MEETING_AGENDA:
    "Skills and Equity Committee Meeting Agenda & Minutes",
  EMP201: "EMP201",
};

export type AutoSuggestType = {
  value: string;
  label: string;
  id: number;
};

export type SelectDropdownType = {
  value: number;
  label: string;
};
